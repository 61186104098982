import React from 'react';
import img1 from '../assets/littleflockimg1.jpg';


const Section1 = () => {
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='rounded-md w-[500px] mx-auto my-4' src={img1} alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='text-[#D95323] font-bold '>Matthew 18:20</p>
          <h1 className='md:text-xl sm:text-l text-l font-bold py-2'>"For where two or three are gathered together in My name, I am there in the midst of them.”
</h1>
          <p>
          We are a small congregation of the Bride of Jesus Christ in this area of Kitchener, Waterloo and Cambridge, in the Province of Ontario, Canada.

We are of Apostolic Faith, Believers of the End-Time Message according to Malachi 4:5-6, Luke 17:30 and Revelation 10:7.

We would be very happy to have you and your loved ones worshiping with us. May God richly bless you
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section1;