import React from 'react';
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import Section1 from './components/Section1'
import Gallery from './components/Gallery'
import Footer from './components/Footer'
import Section2 from './components/Section2'


function App() {
  return (
    <div>
    <Navbar />
    <Hero />
    <Section1 />
    <Section2 />
    <Gallery />
    <Footer />
    </div>
  );
}

export default App;
