import React, {useState} from 'react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'

const style = {color: "black"}
const Navbar = () => {
    const [nav, setNav] = useState(true);
  
    const toggleNav = () => {
      setNav(!nav);
    };

  return (
 
     <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
        <h1 className='w-full text-3xl font-bold text-[#0C0C0D]'>The Little Flock Tabernacle</h1>
        <ul className=' hidden md:flex text-[#0C0C0D]' style={{ zIndex: 1 }}>
            <li className='p-4'>Home</li>
            <a href="#Gallery">
            <li className='p-4'>Gallery</li></a>
            <a href="#Contact">
            <li className='p-4'>Contact</li></a>
        </ul>
        <div onClick={toggleNav} className='block md:hidden'>

        {!nav ? <AiOutlineClose size={20} style={style}/> : <AiOutlineMenu size={20} style={style} />}
        </div>
        <div className={!nav ? 'fixed left-0 top-0 w-[60%] border-r h-full bg-[#590202] ease-in-out duration-500' : 'fixed left-[-100%]'} >
        <h1 className='w-full text-3xl font-bold text-[#F2C49B] m-4'>The Little Flock Tabernacle</h1>
            <ul className='pt-12 uppercase p-4' style={{ zIndex: 1 }}>
            <li className='p-4 border-b border-gray-600'>Home</li>
            <a href="#Gallery">
            <li className='p-4 ' onClick={toggleNav} >Gallery</li></a>
            <a href="#Contact">
            <li className='p-4 ' onClick={toggleNav} >Contact</li></a>
     
            </ul>
            </div>
  </div>
  )
}

export default Navbar