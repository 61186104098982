import React from "react";
import { ReactTyped } from "react-typed";
import heavenly from '../assets/heavenly.png'

const Hero = () => {
  return (
    <div className="text-white relative">
    {/* Background Image */}
    <img
      src={heavenly}
      alt="Description of your image"
      className="absolute inset-0 w-full h-full opacity-50"
      style={{ zIndex: -1 }}
      />
    <div className="text-black">
      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <ReactTyped
          className="pl-2 md:pl-4 md:text-1xl sm:text-l text-l font-bold text-[#0C0C0D] pt-8"
          strings={[
            "Fear not little Flock, for it is your Father's good pleasure to give you the kingdom",
          ]}
          typeSpeed={20}
          backspeed={140}
          onComplete={(self) => self.cursor.remove()}
        />

        <h1 className="md:text-8xl sm:text-7xl text-5xl font-bold md:py-6 pt-3 text-[#0C0C0D]">
          Welcome
        </h1>
        <div className="flex justify-center items-center ">
          <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4"></p>
        </div>
        <p className="md:text-2xl text-xl font-bold text-gray-500"></p>

      </div>
    </div>
    </div>
  );
};

export default Hero;
