import React from 'react'

import churchprayer from '../assets/churchprayer.jpg';
import congregation from '../assets/congregation.jpg';
import littleflockimg1 from '../assets/littleflockimg1.jpg';
import menofchurch from '../assets/menofchurch.jpg';
import pastorjose from '../assets/pastorjose.jpg';
import pastormission from '../assets/pastormission.jpg';
import worship from '../assets/worship.jpg';
import apostle1 from '../assets/apostle1.jpg';
import apostle2 from '../assets/Apostle2.jpg';
import congregationpraying from '../assets/congregation_praying.jpg';
import prayer1 from '../assets/prayer1.jpg';

const Destinations = () => {
  return (
    <section id="Gallery">

    <div className='w-full mx-auto py-16 px-4 text-center bg-white'>
        <h1 className='w-full bg-[#D9D1C7] md:pl-4 md:text-3xl sm:text-2xl text-2xl pt-12 pb-12 font-bold text-[#0C0C0D]'>Gallery of the Saints</h1>
        <p className='py-4'></p>
        <div className='grid grid-rows-none md:grid-cols-5 py-4 gap-2 md:gap-4 max-w-[1240px] mx-auto'>
            <img className='w-full h-full object-cover col-span-2 md:col-span-3 row-span-2' src={pastorjose} alt="/" />
            <img className='w-full h-full object-cover' src={churchprayer} alt="/" />
            <img className='w-full h-full object-cover' src={congregation} alt="/" />
            <img className='w-full h-full object-cover' src={littleflockimg1} alt="/" />
            <img className='w-full h-full object-cover' src={pastorjose} alt="/" />
            <img className='w-full h-full object-cover' src={pastormission} alt="/" />
            <img className='w-full h-full object-cover' src={worship} alt="/" />
            <img className='w-full h-full object-cover' src={menofchurch} alt="/" />
            <img className='w-full h-full object-cover' src={apostle1} alt="/" />
            <img className='w-full h-full object-cover' src={apostle2} alt="/" />
            <img className='w-full h-full object-cover' src={congregationpraying} alt="/" />
            <img className='w-full h-full object-cover' src={prayer1
            } alt="/" />



        </div>
    </div>
  </section>

  )
} 

export default Destinations