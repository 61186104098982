import React from 'react';
import {
  FaFacebookSquare,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-[#0C0C0D'>
      <div>
        <h1 className='w-full text-3xl font-bold text-[#0C0C0D]'>The Little Flock Tabernacle</h1>
        <p className='py-4'>Follow our Facebook Page if for any reason you are not able to make it to a physical church on Sunday. We stream most of our Sunday services</p>
        <div className='flex justify-between md:w-[75%] my-6'>
        <a
              className="flex gap-4 justify-center items-center"
              href="https://www.facebook.com/littleflockchurchkitchener"
            >
              <FaFacebookSquare className="cursor-pointer" size={30} />
            </a>
        </div>
      </div>

    </div>
      
    
  );
};

export default Footer;